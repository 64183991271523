





import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import can from '@/shared/helpers/can.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Form from '@/shared/components/form/Form.vue'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import { downloadFile } from '@/shared/helpers/download-file.helper'

@Component({
  methods: { can, __ },
  components: { Form },
})
export default class SummaryGeneration extends Vue {
  form!: FormBase

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/projects/${ this.$route.params.uuid }/generate-summary`)
      .setMethod(HttpMethod.POST)
      .setResponseType('arraybuffer')
      .setFields([
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('with_checklists')
          .setTitle(__('company.views.projects.show.tabs.summary.summary.form.checklist'))
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('with_instructions')
          .setTitle(__('company.views.projects.show.tabs.summary.summary.form.instruction'))
          .setSize(FieldSizes.half),
      ])
      .setInitialValues({
        with_checklists: true,
        with_instructions: true,
      })
      .setOnSuccess((data: any, entry: any, response: any) => {
        downloadFile(response, 'summary')
      })
      .setSubmit({
        text: __('company.views.projects.show.tabs.summary.summary.form.submit'),
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
